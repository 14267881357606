var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    { attrs: { label: "Tipo" } },
    [
      _c("multiselect", {
        attrs: {
          options: _vm.options,
          "allow-empty": false,
          placeholder: "Escoge un tipo...",
          label: "name",
          "track-by": "value"
        },
        on: { input: _vm.handleInput },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }