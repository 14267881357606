<template>
  <div>
    <b-field :message="errors.first('clients')" :type="errors.has('clients') ? 'is-danger' : ''" label="Clientes">
      <multiselect
        v-model="value"
        :options="cloneClients"
        :close-on-select="false"
        :block-keys="['Tab', 'Enter']"
        :deselectLabel="``"
        :selectLabel="``"
        :selectGroupLabel="``"
        :deselectGroupLabel="``"
        :hideSelected="true"
        :searchable="true"
        :multiple="true"
        :custom-label="customLabel"
        :loading="requestClients"
        :disabled="requestClients"
        :group-select="true"
        placeholder="Escoge un Cliente..."
        group-values="children"
        group-label="name"
        label="name"
        track-by="name"
        @input="handleInput"
      >
        <template slot="noResult">No hay resultados</template>
        <template slot="noOptions">
          <p>Ocurrió un problema</p>
        </template>
      </multiselect>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    Multiselect
  },
  props: {
    condition: {
      type: String
    }
  },
  data() {
    return {
      languages: [
        { id: 1, name: 'Español', shortHand: 'es' },
        { id: 2, name: 'Inglés', shortHand: 'en' },
        { id: 3, name: 'Portugués', shortHand: 'pt' }
      ],
      value: [],
      selectedLanguage: 'es'
    }
  },
  computed: {
    ...mapGetters(['clients', 'requestClients', 'requestClientsError']),
    isConditioned() {
      if (this.condition === 'firstparty') {
        return true
      }
      return false
    },
    cloneClients() {
      if (this.condition === 'firstparty' && this.clients.length) {
        const clonedClients = this.clients && cloneDeep(this.clients)
        let newAll = clonedClients.find((e) => e.id === 0)
        if (newAll) {
          newAll.children.forEach((val) => {
            val.$isDisabled = true
          })
          return clonedClients
        }
      }
      return this.clients ? this.clients : []
    }
  },

  methods: {
    handleInput(event) {
      const ids = event.map((e) => e.id)
      this.$emit('input', ids)
    },
    customLabel({ name, id }) {
      return `${name} (${id})`
    }
  }
}
</script>

<style lang="scss">
.multiselect__tag {
  background: $primary-light;
}
.multiselect__tag-icon:after {
  color: white;
  font-size: 14px;
}
.multiselect__tag-icon:hover {
  background: $primary;
}
.multiselect__option--highlight {
  background: $primary-light;
  outline: none;
  color: #fff;
}
</style>
