var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        {
          attrs: {
            message: _vm.errors.first("segments"),
            type: _vm.errors.has("segments") ? "is-danger" : "",
            label: "Segment Type"
          }
        },
        [
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.segmentType,
                "block-keys": ["Tab", "Enter"],
                multiple: true,
                searchable: true,
                deselectLabel: "",
                selectLabel: "",
                hideSelected: true,
                loading: _vm.requestSegmentTypes,
                disabled: _vm.requestSegmentTypes,
                "custom-label": _vm.customLabel,
                "close-on-select": false,
                placeholder: "Escoge un Segmento...",
                label: "name",
                "track-by": "name"
              },
              on: { input: _vm.handleInput },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            props.option.name.split("")[0].toUpperCase() +
                              props.option.name.slice(1)
                          ) +
                          "\n      "
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            [
              _c("template", { slot: "noResult" }, [
                _vm._v("No hay resultados")
              ]),
              _c("template", { slot: "noOptions" }, [
                _c("p", [_vm._v("Ocurrió un problema")])
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }