var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        {
          attrs: {
            message: _vm.errors.first("plataformas"),
            type: _vm.errors.has("plataformas") ? "is-danger" : "",
            label: "Plataforma"
          }
        },
        [
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.platforms,
                "close-on-select": false,
                "block-keys": ["Tab", "Enter"],
                searchable: true,
                deselectLabel: "",
                selectLabel: "",
                "custom-label": _vm.customLabel,
                hideSelected: true,
                multiple: true,
                loading: _vm.requestPlatforms,
                disabled: _vm.requestPlatforms,
                placeholder: "Escoge una Plataforma...",
                label: "name",
                "track-by": "name"
              },
              on: { input: this.handleInput },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            [
              _c("template", { slot: "noResult" }, [
                _vm._v("No hay resultados")
              ]),
              _c("template", { slot: "noOptions" }, [
                _c("p", [_vm._v("Ocurrió un problema")])
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }