<template>
  <b-field label="Tipo">
    <multiselect
      v-model="selected"
      :options="options"
      :allow-empty="false"
      placeholder="Escoge un tipo..."
      label="name"
      track-by="value"
      @input="handleInput"
    ></multiselect>
  </b-field>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      selected: {
        name: 'First Party',
        value: 'firstparty'
      },
      options: [
        {
          name: 'First Party',
          value: 'firstparty'
        },
        {
          name: 'Third Party',
          value: 'thirdparty'
        }
      ]
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.value)
    }
  }
}
</script>
