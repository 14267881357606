<template>
  <div>
    <b-field :message="errors.first('plataformas')" :type="errors.has('plataformas') ? 'is-danger' : ''" label="Plataforma">
      <multiselect
        v-model="value"
        :options="platforms"
        :close-on-select="false"
        :block-keys="['Tab', 'Enter']"
        :searchable="true"
        :deselectLabel="``"
        :selectLabel="``"
        :custom-label="customLabel"
        :hideSelected="true"
        :multiple="true"
        :loading="requestPlatforms"
        :disabled="requestPlatforms"
        placeholder="Escoge una Plataforma..."
        label="name"
        track-by="name"
        @input="this.handleInput"
        ><template slot="noResult">No hay resultados</template>
        <template slot="noOptions">
          <p>Ocurrió un problema</p>
        </template>
      </multiselect>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      value: [],
      languages: [
        { id: 1, name: 'Español', shortHand: 'es' },
        { id: 2, name: 'Inglés', shortHand: 'en' },
        { id: 3, name: 'Portugués', shortHand: 'pt' }
      ],
      selectedLanguage: 'es'
    }
  },
  computed: {
    ...mapGetters(['platforms', 'requestPlatforms', 'requestPlatformsError'])
  },
  methods: {
    handleInput(e) {
      const ids = e.map((e) => e.id)
      return this.$emit('input', ids)
    },
    customLabel({ name, id }) {
      return `${name} (${id})`
    }
  }
}
</script>

<style lang="scss" scoped>
.cust-field {
  width: 30%;
}
</style>
